<template>
    <div class="auth-wrapper auth-v1">
        <div class="auth-inner">
            <v-card
                :disabled="loading"
                class="auth-card"
            >
                <!-- logo and title -->
                <v-card-title class="d-flex align-center justify-center py-7">
                    <router-link
                        to="/"
                        class="d-flex align-center"
                    >
                        <v-img
                            :src="appLogo"
                            max-height="30px"
                            max-width="30px"
                            alt="logo"
                            contain
                            class="me-3 "
                        ></v-img>

                        <h2 class="text-2xl font-weight-semibold">
                            {{ appName }}
                        </h2>
                    </router-link>
                </v-card-title>

                <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                        {{ $trans('Change password') }} 🔒
                    </p>
                    <p>
                        {{ $trans('hint.reset_password.subtitle') }}
                    </p>
                    <p
                        v-if="!token"
                        class="error--text"
                    >
                        {{ $trans('hint.reset_password.without_token') }}
                    </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                    <v-form
                        v-model="isValid"
                        ref="resetPasswordForm"
                        autocomplete="off"
                    >

                        <v-text-field
                            v-model="email"
                            if="field1"
                            type="email"
                            outlined
                            autocomplete="off"
                            :rules="rules.email"
                            :label="$trans('Email')"
                            placeholder="john@example.com"
                            :error-messages="errors.email"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                        ></v-text-field>

                        <v-text-field
                            v-model="password"
                            if="field2"
                            outlined
                            :type="isPasswordVisible ? 'text' : 'password'"
                            autocomplete="new-password"
                            :rules="rules.password"
                            :label="$trans('New password')"
                            placeholder="············"
                            :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                            :error-messages="errors.password"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                        ></v-text-field>

                        <v-text-field
                            v-model="confirmPassword"
                            if="field3"
                            outlined
                            :type="isConfirmPasswordVisible ? 'text' : 'password'"
                            :rules="rules.confirmPassword"
                            :label="$trans('Password confirmation')"
                            placeholder="············"
                            :append-icon="isConfirmPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                            :error-messages="errors.password_confirmation"
                            @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                        ></v-text-field>

                        <v-btn
                            block
                            :disabled="!isValid"
                            :loading="loading"
                            color="primary"
                            @click="onSubmit"
                        >
                            {{ $trans('hint.reset_password.submit') }}
                        </v-btn>
                    </v-form>
                </v-card-text>

                <!-- back to login -->
                <v-card-actions class="d-flex justify-center align-center">
                    <router-link
                        :to="{name:'auth.login'}"
                        class="d-flex align-center text-sm"
                    >
                        <v-icon
                            size="24"
                            color="primary"
                        >
                            mdi-chevron-left
                        </v-icon>
                        <span>{{ $trans('Back to sign in') }}</span>
                    </router-link>
                </v-card-actions>
            </v-card>
        </div>

        <!-- background triangle shape  -->
        <img
            class="auth-mask-bg"
            height="190"
            :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
        >

        <!-- tree -->
        <v-img
            class="auth-tree"
            width="247"
            height="185"
            src="@/assets/images/misc/tree.png"
        ></v-img>

        <!-- tree  -->
        <v-img
            class="auth-tree-3"
            width="377"
            height="289"
            src="@/assets/images/misc/tree-3.png"
        ></v-img>
    </div>
</template>

<script>
import themeConfig from '@themeConfig'
import EventEmitter from '@/plugins/EventEmitter'
import { resetPassword } from '@apps/auth/api'
import Debugger from '@/utils/Debugger'
import { confirmedValidator, emailValidator, passwordValidator, required } from '@/utils/validation'

export default {
    name: 'ResetPassword',
    computed: {
        token() {
            return this.$route.query.token
        },
        rules() {
            return {
                email: [
                    required,
                    emailValidator
                ],
                password: [
                    required,
                    passwordValidator
                ],
                confirmPassword: [
                    v => confirmedValidator(v, this.password),
                ]
            }
        }
    },
    watch: {
        isValid(flag) {
            if(flag) {
                this.isValid = !!this.token
            }
        }
    },
    data() {
        return {
            isPasswordVisible: false,
            isConfirmPasswordVisible: false,
            email: '',
            password: '',
            confirmPassword: '',
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
            errors: {},
            loading: false,
            isValid: true
        }
    },
    beforeCreate() {
        EventEmitter.emit('guest:bootstrap')
    },
    methods: {
        async onSubmit() {
            if(!this.$refs.resetPasswordForm.validate() || !this.token) {
                return
            }

            this.errors = {}
            this.loading = true

            try {
                await resetPassword({
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.confirmPassword
                })

                await this.$router.push({name: 'auth.login'})
            } catch (error) {
                if(!error || !error.response) {
                    Debugger.error(error)
                }

                if(error.response.data.errors) {
                    this.errors = Object.assign({}, error.response.data.errors)
                }
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
